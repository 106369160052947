import { IWinzoBannerBottomComponentHomePageProps } from '../../../lib/types/components';
import { dynamicCssSize } from "../../../lib/utils/constants/PagesConstants";
import CommonWithdrawalPartnerComponentV2 from '../CommonWithdrawalPartner/CommonWithdrawalPartnerComponentV2';
import {multipleClassName} from "../../../lib/utils/helpers/helper";
import styles from "./WinzoBannerBottomHomePageComponentV2.module.css"

const WinzoBannerBottomComponentHomePageV2 = (props: IWinzoBannerBottomComponentHomePageProps) => {
    const {
        bannerBottomData,
        withdrawalPartnerData
    } = props;

    return (
        bannerBottomData && Object.keys(bannerBottomData).length?
            <div className={multipleClassName("container-fluid", styles.jsxcontainer)}>
                <div className={multipleClassName("container", styles.innerContainer)}>
                    <div className={"row"}>
                        <div className={"col"}>
                            <div className={multipleClassName("row", styles.textContainer)}>
                                <div className={multipleClassName("col", styles.textParent)}>
                                    <div>
                                        <p className={styles.text1}>{bannerBottomData.text1}</p>
                                    </div>
                                    <div>
                                        <p className={styles.text2}>{bannerBottomData.text2}</p>
                                    </div>
                                </div>
                                <div className={multipleClassName("col", styles.textParent)}>
                                    <div>
                                        <p className={styles.text1}>{bannerBottomData.text3}</p>
                                    </div>
                                    <div>
                                        <p className={styles.text2}>{bannerBottomData.text4}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.seperatorLine}></div>
                        <div className={multipleClassName("col", styles.partnerContainer)}>
                            <CommonWithdrawalPartnerComponentV2 withdrawalPartnerData={withdrawalPartnerData}
                                                              isInBanner={true}/>
                        </div>
                    </div>
                </div>
            </div>
            :
            <></>
    )
}

export default WinzoBannerBottomComponentHomePageV2;