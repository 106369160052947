const DisclaimerComponent = () => {
    return (
        <>
            <style jsx>
                {`
                    h6 {
                        font-family: var(--font-primary);
                        text-align: center;
                        font-size: 12px;
                        margin: 8px 0 0 0;
                        color: white;
                        opacity: 0.7;
                        display: none;
                    }

                    @media screen and (max-width: 576px) {
                        h6 {
                            display: block;
                        }
                    }
                `}
            </style>
            <h6>This download is not from Google Play Store</h6>
        </>
    )
}

export default DisclaimerComponent;