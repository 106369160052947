import {ICommonWithdrawalPartnerComponentProps} from "../../../lib/types/components";
import {currentPageURL, dynamicCssSize} from "../../../lib/utils/constants/PagesConstants";
import CustomImageComponent from '../CustomImage/CustomImageComponent';
import {GET_APP_FACEBOOK_URL, GET_APP_FB_URL} from "../../../lib/utils/constants/GlobalConstants";
import {multipleClassName} from "../../../lib/utils/helpers/helper";
import styles from "./CommonWithdrawalPartnerComponentV2.module.css"

const CommonWithdrawalPartnerComponentV2 = (props: ICommonWithdrawalPartnerComponentProps) => {

    const {withdrawalPartnerData, isInBanner} = props;
    const isGetAppFacebook: boolean = (currentPageURL === GET_APP_FACEBOOK_URL) || (currentPageURL === GET_APP_FB_URL);

    return (
        withdrawalPartnerData ?
            <div className={multipleClassName("col", styles.withdrawalContainer)} style={{
                display: `${isInBanner ? 'flex' : 'none'}`,
                fontStyle: `${isGetAppFacebook ? "italic" : 'normal'}`,
                letterSpacing: `${isGetAppFacebook ? '1px' : 'normal'}`
            }}>
                <p style={{
                    fontSize: isGetAppFacebook ? dynamicCssSize(25, 17) : dynamicCssSize(22, 10),
                    fontWeight: `${isGetAppFacebook ? 900 : 600}`
                }}>{withdrawalPartnerData.text1}</p>
                <div>
                    <CustomImageComponent
                        layout={'fill'}
                        src={withdrawalPartnerData.bannerImg.srcV2}
                        objectFit={"contain"}
                        alt={withdrawalPartnerData.bannerImg.alt || "withdrawal Partner"}
                        priority={true}
                    />
                </div>
            </div>
            :
            <></>
    )
}

export default CommonWithdrawalPartnerComponentV2;